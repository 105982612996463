.Box {
	background-color: rgb(254, 165, 61);
	border-radius: 5px; 
	height: 90px;
	width: 130px;
	text-align: center;
	font-size: 1.3rem;
}

.Box h3 {
	color: rgb(255, 255, 255);
	text-align: center;
	font-size: 1rem;
}
