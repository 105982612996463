.imageContainer {
	display: flex;
	overflow-x: auto;
	white-space: nowrap;

	> a {
		margin-left: 10px;

		&:first-child {
			margin-left: 0;
		}

		img {
			max-height: 100px;
		}
	}
}