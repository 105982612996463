.adminModal {
	background-color: var(--color-background-container);
	border: 1px solid var(--color-border); border-radius: var(--border-radius);
	box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.15); min-width: 256px;

	& > div:first-child {
		display: flex; justify-content: space-between;
		font-weight: bold; border-bottom: 1px solid var(--color-border);

		& > img {
			width: 12px; cursor: pointer;
		}
	}

	& > div {
		padding: 8px;
	}
}
