@import '../styles/media.scss';

.bonusLadder {
	display: flex; flex-direction: column; gap: 12px;

	& > .bonusLadderMilestone {
		display: flex; align-items: center; gap: 12px; padding: 8px;
		border: 0.5px solid rgba(25, 25, 38, 0.12); border-radius: 8px;
		background-color: rgba(25, 25, 38, 0.03);

		& > .icon {
			display: flex; align-items: center; position: relative;
			justify-content: center; border-radius: 4px;
			border: 0.5px solid rgba(0, 0, 0, 0.04);

			@media #{$desktop-and-tablet} {
				min-width: 48px; max-width: 48px; min-height: 48px;
				max-height: 48px; font-size: 1.2rem;
			}

			@media #{$mobile} {
				min-width: 40px; max-width: 40px; min-height: 40px;
				max-height: 40px; font-size: 1rem;
			}

			& > img {
				position: absolute; top: -4px; right: -4px;
			}
		}

		& > .text {
			flex-grow: 1;

			& > div:first-child {
				font-size: 12px; line-height: 14px; font-weight: 500;
				color: rgba(25, 25, 38, 0.90);

				@media #{$desktop-and-tablet} {
					font-size: 12px; line-height: 14px;
				}

				@media #{$mobile} {
					font-size: 10px; line-height: 12px;
				}
			}

			& > div:last-child {
				color: rgba(25, 25, 38, 0.90);
				font-weight: bold; letter-spacing: -0.29px;

				@media #{$desktop-and-tablet} {
					font-size: 14px; line-height: 18px;
				}

				@media #{$mobile} {
					font-size: 12px; line-height: 14px;
				}
			}
		}

		& > .suffix {
			white-space: nowrap;

			@media #{$desktop-and-tablet} {
				font-size: 12px; line-height: 14px;
			}

			@media #{$mobile} {
				font-size: 10px; line-height: 12px;
			}
		}
	}
}
