.details_container {
	background-color: #45304C;
	border-radius: 10px;
	padding: 20px;
	box-shadow: 1.5px 1.5px 2px .5px rgb(254, 165, 61);
	margin: 15px;
	height: 100%;
}

.details_container div.titleBar{
	color: azure;
}