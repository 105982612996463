.containerListItem {
	display: flex; padding: 8px; border-bottom: 1px solid var(--color-border);
	align-items: center; text-decoration: none;

	&.clickable {
		cursor: pointer; transition: background-color 0.4s;

		&:hover {
			background-color: var(--color-background-hover);
		}
	}

	& > div:first-child {
		& > div:first-child {
			color: var(--color-text-title); font-weight: bold;
		}

		& > div:last-child {
			font-weight: 400; color: var(--color-text-default);
		}
	}

	& > .gap { flex-grow: 1; }

	&:last-child {
		border-bottom: 0;
	}
}
