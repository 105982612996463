@import '../styles/media.scss';

.autoStack {
	display: flex;

	@media #{$mobile} {
		flex-direction: column;

		& > div { margin-bottom: 24px; }
		& > div:last-child { margin-bottom: 0; }
	}

	@media #{$desktop-and-tablet} {
		&.forceEvenSplit {
			& > div { flex-basis: 0; }
		}

		&.invert {
			flex-direction: row-reverse;

			& > div { margin-left: 32px; }
			& > div:last-child { margin-left: 0; }
		}

		&.center {
			align-items: center;
		}

		&:not(.invert) {
			& > div { margin-right: 32px; }
			& > div:last-child { margin-right: 0; }
		}

		& > div { flex-grow: 1; }
	}
}
