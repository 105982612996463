@import '../styles/media.scss';

.container {
	background-color: #FFF;
	border: 0.5px solid var(--rave-color-border-light);
	border-radius: 8px;

	@media #{$mobile} { border-radius: 8px; }
	@media #{$desktop-and-tablet} { border-radius: 12px; }
}
