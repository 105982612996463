body {
	margin: 32px; font-family: var(--font-default);
	background-color: var(--color-background-ambient);
	color: var(--color-text-default); font-size: var(--font-size-default);
}

a {
	color: var(--color-theme); font-weight: bold; text-decoration: none;
	cursor: pointer;
}
