.alreadyInvitedFriends {
	& > div:first-of-type {
		font-size: 12px;

		& > span { font-weight: bold; }
	}

	& > div:last-of-type {
		display: flex; align-items: center;
	
		& > div {
			width: 32px; height: 32px; border-radius: 50%; border: 2px solid #FFF;
			display: flex; align-items: center; margin-right: -8px;
			justify-content: center;

			&:last-of-type { font-size: 11px; font-weight: bold; }
		}
	}
}
