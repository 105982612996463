.heroSkuDisplay {
	width: 160px; height: 160px; position: relative;

	& > div:not(.price) {
		position: absolute; border-radius: 14px; left: 50%; top: 50%;
	}

	& > div:nth-child(1) {
		width: 100%; height: 100%; z-index: 1;
		transform: translate(-50%, -50%) rotate(-6deg);
	}

	& > div:nth-child(2) {
		width: 90%; height: 90%; z-index: 2;
		transform:  translate(-50%, -50%) rotate(-3deg);
	}

	& > div:nth-child(3) {
		width: 80%; height: 80%; z-index: 3;
		transform: translate(-50%, -50%) rotate(-1deg);
	}

	& > div:nth-child(4) {
		width: 70%; height: 70%; z-index: 4; background-position: center;
		background-size: cover; transform: translate(-50%, -50%);
	}

	& > .price {
		color: #FFF; font-weight: bold; padding: 4px; font-size: 10px;
		line-height: 12px;
		position: absolute; left: 50%; top: 15%; transform: translate(-50%, -50%);
		background-color: #222; z-index: 5; border-radius: 4px;
		white-space: nowrap;
	}
}
