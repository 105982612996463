.loader {
	border-style: solid; border-color: rgba(0, 0, 0, 0.1);
	border-top-style: dashed; border-top-color: var(--rave-color-theme);
	border-bottom-style: dashed;

	// Some Shopify sites have div:empty { display: none }...
	display: block !important;
	
	animation-name: loader; animation-duration: 0.7s;
	animation-iteration-count: infinite; animation-timing-function: linear;

	&.alternate { border-top-color: var(--rave-color-theme-alternate); }

	@keyframes loader {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
}
