.dateRangeSelector {
	width: 100%; box-sizing: border-box; outline: 0;
	background-color: #191d22; border: 1px solid var(--color-border);
	border-radius: 4px; color: var(--color-text-title); height: 34px;
	display: flex; align-items: center;

	& > div {
		padding: 8px; transition: background-color 0.4s; cursor: pointer;
		flex-grow: 1; text-align: center;

		&:hover { background-color: rgba(255, 255, 255, 0.025); }
	}

	& > img {
		width: 12px; margin-left: 4px; margin-right: 4px;
	}
}

.dateInput {
	width: 100%; box-sizing: border-box; padding: 8px; outline: 0;
	background-color: #191d22; border: 1px solid var(--color-border);
	border-radius: 4px; color: var(--color-text-title);
}
