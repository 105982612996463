@import '../styles/media.scss';

.rampMilestoneDisplay {
	container-type: inline-size;

	& > .progressBar {
		height: 10px; border-radius: 10px; background-color: #EDEFF1;
		position: relative; margin-bottom: 48px;

		& > .inner {
			position: absolute; width: 5%; height: 10px; border-radius: 10px;
		}

		& > .bonusIndicatorHolder {
			position: absolute; width: 90%; left: 5%;

			& > .bonusIndicator {
				position: absolute; padding: 3px; background-color: #FFF;
				border-radius: 4px; border: 1px solid rgba(0, 0, 0, 0.075);
				transform: translate(-50%, -30%); font-size: 12px; font-weight: bold;
				color: #333; display: flex; flex-direction: column;
				align-items: center;

				@media #{$mobile} { padding: 2px; font-size: 10px; }
		
				& > img {
					max-width: 24px; max-height: 24px; min-width: 24px;
					min-height: 24px;

					@media #{$mobile} {
						max-width: 16px; max-height: 16px; min-width: 16px;
						min-height: 16px;
					}
				}
			}
		}
	}

	& > .milestoneTiles {
		display: grid; grid-template-columns: repeat(4, 1fr);
		gap: 24px; overflow-x: scroll; overflow-y: hidden;
		scrollbar-width: none; -ms-overflow-style: none;

		&::-webkit-scrollbar { width: 0; height: 0; }

		& > .milestoneTile {
			flex-basis: 0; flex-grow: 1; min-width: 104px;

			& > .image {
				width: 100%; aspect-ratio: 1; background-size: cover;
				background-position: center; background-color: #EDEFF1;
				border-radius: 12px; margin-bottom: 12px;
				border: 2px solid #E6E6E6;
			}

			& > .cardHolder {
				background-color: #EDEFF1; border-radius: 12px; aspect-ratio: 1;
				margin-bottom: 12px; width: 100%; position: relative;
				border: 2px solid #E6E6E6;

				& > svg {
					width: 90%; top: 50%; left: 50%; position: absolute;
					transform: translate(-50%, -40%);
				}
			}

			& > .title {
				color: #222; font-weight: bold; font-size: 16px;
				line-height: 18px; letter-spacing: -0.01rem; margin-bottom: 4px;
				white-space: nowrap;
			}

			& > .emptyTitle {
				background-color: rgba(0, 0, 0, 0.05); height: 16px;
				border-radius: 2px; margin-bottom: 4px; width: 50%;
			}

			& > .subtitle {
				color: #555; font-size: 16px; line-height: 18px;
				letter-spacing: -0.01rem;
			}

			& > .emptySubtitle {
				background-color: rgba(0, 0, 0, 0.025); height: 16px;
				width: 70%; border-radius: 2px;
			}

			@media #{$mobile} {
				& > .title { font-size: 14px; line-height: 16px; }
				& > .subtitle { font-size: 14px; line-height: 16px; }
			}
		}
	}
}
