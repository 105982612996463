@import '../../styles/media.scss';

.containerControls {
	display: flex; border-bottom: 1px solid var(--color-border); padding: 8px;

	&:last-child {
		border-bottom: 0;
	}

	@media #{$desktop-and-tablet} { justify-content: flex-end; }
	@media #{$mobile} {
		flex-direction: column; justify-content: center; align-items: center;
	}

	& > .control {
		@media #{$desktop-and-tablet} {
			margin-right: 8px;

			&:last-child { margin-right: 0; }
		}

		@media #{$mobile} {
			width: 100%; margin-bottom: 8px;

			&:last-child { margin-bottom: 0; }
		}
	}
}
