.styledTable {
	overflow-x: auto; max-width: 100%;

	& > table {
		width: 100%; overflow-x: scroll; scrollbar-width: thin;
		border-collapse: collapse;

		&::-webkit-scrollbar { width: 8px; }
		&::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.1); }
		&::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0.1);
			border-radius: 8px;
		}

		& > * {
			& > tr {
				& > th {
					color: var(--color-text-title);
				}

				& > td, & > th {
					padding: 12px;
					white-space: nowrap;
					text-align: left;
				}

				&:nth-child(2n+1) > td {
					background-color: rgba(0, 0, 0, 0.15);
				}

				&:last-child > td { border-bottom: 0; }
			}
		}
	}
}
