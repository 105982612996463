.containerListButton {
	display: flex; padding: 8px; border-bottom: 1px solid var(--color-border);
	color: var(--color-text-title); font-weight: bold;
	transition: background-color 0.4s, opacity 0.4s; align-items: center;

	&.disabled {
		opacity: 0.5; cursor: not-allowed
	}

	& > img {
		filter: brightness(0) invert(1); max-width: 16px; min-width: 16px;
		max-height: 16px; min-height: 16px; margin-right: 6px;
	}

	&:not(.disabled):hover {
		background-color: var(--color-background-hover);
	}

	&:last-child {
		border-bottom: 0;
	}
}
