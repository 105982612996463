.container {
	background-color: #FFF; border-radius: 6px; padding: 16px;
	box-shadow: var(--rave-box-shadow); position: relative; max-width: 384px;
	font-family: var(--rave-font-default); line-height: 16px;
	letter-spacing: -0.02em; color: var(--rave-color-text-default);

	.titleBar {
		display: flex; align-items: flex-start; justify-content: space-between;

		& > .title {
			font-size: 24px; line-height: 28px; font-weight: bold;
			letter-spacing: -0.05rem; color: #1E1E1E;
		}

		& > .close {
			background-color: rgba(0, 0, 0, 0.05); border-radius: 4px;
			padding: 8px; cursor: pointer;

			& > img {
				display: block; height: 12px;
			}
		}
	}

	.description {
		font-size: 14px; line-height: 18px; color: #555555;
	}

	.subtitle {
		font-size: 16px; line-height: 18px; letter-spacing: -0.03rem;
		color: #171717; font-weight: bold;
	}
}
