.containerSegment {
	position: relative; padding: 12px;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		border-bottom: 0.5px solid var(--rave-color-border-light);
		left: 5%;
		width: 90%;
	}

	&:last-child::after {
		display: none;
	}
}
