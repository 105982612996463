.productImageDisplay {
	min-width: 48px; min-height: 48px; background-color: var(--rave-color-background-dark);
	border-radius: 6px; position: relative; margin-right: 8px;

	& > img {
		max-width: 32px; max-height: 32px; transform: translate(-50%, -50%);
		position: absolute; top: 50%; left: 50%; border-radius: 2px;
	}

	&.large {
		min-width: 86px; min-height: 86px;

		& > img { max-width: 64px; max-height: 64px; }
	}
}
