@import '../styles/media.scss';

.rewardTiles {
	display: flex; flex-direction: row; align-items: center; gap: 16px;
	text-align: center; justify-content: space-around;

	@media #{$mobile} { width: 100%; }

	& > .tile {
		padding: 16px 8px; background-color: #FFF; border-radius: 6px;
		box-shadow: 0px 0.5px 2px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.05), 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.02);

		@media #{$desktop-and-tablet} { min-width: 160px; width: 50%; }
		@media #{$mobile} { padding: 12px 6px; flex-basis: 0; flex-grow: 1; }

		& > div:first-of-type {
			font-size: 14px; line-height: 16px; letter-spacing: -0.02em;
			margin-bottom: 4px; opacity: 0.7;
			font-weight: 500;

			@media #{$mobile} { font-size: 12px; line-height: 14px; }
		}

		& > div:last-of-type {
			font-weight: bold; font-size: 28px; line-height: 32px;
			letter-spacing: -0.04em;
			color: var(--rave-color-text-title);

			@media #{$mobile} { font-size: 20px; line-height: 24px; }
		}
	}
}
