.copyBox {
	padding: 12px; background-color: rgba(81, 24, 238, 0.04);
	border: 1px solid rgba(81, 24, 238, 0.24); display: flex;
	align-items: center; justify-content: space-between; border-radius: 6px;

	& > div:first-child {
		color: #1E1E1E; font-size: 14px; line-height: 20px;
		letter-spacing: -0.14px; white-space: nowrap; overflow-x: hidden;
		text-overflow: ellipsis;
	}

	& > div:last-child {
		color: var(--rave-color-theme); font-size: 14px; line-height: 20px;
		font-weight: 500; letter-spacing: -0.14px; margin-left: 16px;
		cursor: pointer;
	}
}

.copyBoxGrey {
	padding: 12px; background-color: var(--rave-color-background-dark);
	display: flex; align-items: center; justify-content: space-between;
	border-radius: 6px;

	& > div:first-child {
		color: #1E1E1E; font-size: 14px; line-height: 20px;
		letter-spacing: -0.14px; white-space: nowrap; overflow-x: hidden;
		text-overflow: ellipsis;
	}

	& > div:last-child {
		color: var(--rave-color-theme); font-size: 14px; line-height: 20px;
		font-weight: bold; letter-spacing: -0.14px; margin-left: 16px;
		cursor: pointer;
	}
}