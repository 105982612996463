.button {
	padding: 12px 16px; display: flex; justify-content: center;
	align-items: center; border-radius: var(--rave-border-radius); font-weight: bold;
	cursor: pointer; outline: 3px solid rgba(0, 0, 0, 0); text-align: center;
	white-space: nowrap; user-select: none; font-size: 14px; line-height: 18px;
	letter-spacing: -0.02em;

	transition:
		opacity 0.4s,
		transform 0.4s,
		color 0.4s,
		border-color 0.4s,
		background-color 0.4s,
		background 0.4s,
		outline-color 0.4s,
		filter 0.4s;

	&.disabled {
		opacity: 0.5; pointer-events: none; cursor: not-allowed;
	}

	&.filled {
		background-color: var(--rave-color-theme); color: #FFF;
		
		&:hover { filter: brightness(1.2); }
	}

	&.alternate {
		background-color: var(--rave-color-theme-alternate); color: #FFF;
		font-size: medium; padding: 10px 32px;
		
		&:hover { background-color: var(--rave-color-theme-alternate-hover); }
	}

	&.gradient {
		background: linear-gradient(130.43deg, #6352ED -52.63%, #8275F1 90.77%);
		color: #FFF;

		&:hover {
			background: linear-gradient(130.43deg, #7463FE -52.63%, #9386F2 90.77%);
		}
	}

	&.light {
		background-color: var(--rave-color-theme-light); color: var(--rave-color-theme);
		border: 2px solid var(--rave-color-theme); padding: 8px 16px;

		&:hover {
			color: var(--rave-color-theme-hover);
			border-color: var(--rave-color-theme-hover);
		}
	}

	&.gray {
		background-color: #f2f2f2; color: rgba(30, 30, 30, 0.90);
		border: 1px solid rgba(0, 0, 0, 0.1); padding: 12px 24px;

		&:hover {
			background-color: #eaeaea;
		}
	}

	&.noBackground {
		background-color: rgba(0, 0, 0, 0); color: rgba(30, 30, 30, 0.90);
		border: 0; padding: 12px 24px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}

	&:not(.disabled):active {
		transform: scale(0.95);
	}

	&:not(.disabled):focus {
		outline-color: rgba(0, 0, 0, 0.1);
	}

	& > img {
		margin-left: 6px; height: 18px;
	}

	&.noBackgroundMobile {
		background-color: rgba(0, 0, 0, 0); color: #5118EE;
		border: 0; padding: 12px 0px;
	}
}
