@import '../styles/media.scss';

.draggableGridContainer {
	width: 100%; height: 100%; overflow: hidden; position: relative;
	box-sizing: border-box;

	@media #{$mobile} { padding: 16px; }

	& > .grid {
		width: 100%; height: 100%;

		@media #{$desktop-and-tablet} { cursor: move; }

		@media #{$mobile} {
			position: absolute; top: 0; left: 0;
			overflow-y: scroll;
		}

		& > div {
			user-select: none; z-index: 1;
	
			@media #{$desktop-and-tablet} {
				position: absolute; min-width: 0; white-space: nowrap;
				transform: translate(-50%, -50%);
			}

			@media #{$mobile} {
				padding: 16px;
			}
		}
	}

	& > .dimmer {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%;
		background-color: rgba(0, 0, 0, 0.5); z-index: 2;
		opacity: 0; pointer-events: none; transition: opacity 0.4s;

		&.active { opacity: 1; }
	}
}
