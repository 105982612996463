@import '../styles/media.scss';

.linkBox {
	background-color: #FAFAFA; color: #1E1E1E;
	border-radius: var(--rave-border-radius); display: flex;
	justify-content: space-between; align-items: center; box-sizing: border-box;
	width: 100%; cursor: pointer; user-select: none;
	border-width: 1px; border-style: solid; padding: 8px;

	& > .link {
		text-overflow: clip; white-space: nowrap; overflow: hidden;
		position: relative; flex-grow: 1; height: 19px; margin-right: 4px;

		& > .linkLimit {
			width: 100%; position: absolute; top: 0; left: 0; height: 100%;
		}

		& > .fader {
			height: 100%; width: 32px; position: absolute; top: 0; right: 0;
			background-image: linear-gradient(to right, rgba(247, 245, 253, 0), rgba(247, 245, 253, 1) 80%)
		}
	}
}

.buttonHolder {
	width: 100%; margin-top: 12px;
}
