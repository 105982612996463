.modalBase {
	background-color: rgba(0, 0, 0, 0.7); position: fixed; top: 0; left: 0;
	width: 100%; height: 100%; z-index: 2147483647; display: flex; display: none;
	justify-content: center; align-items: center; pointer-events: none;
	flex-direction: column; opacity: 0; transition: opacity 0.4s;

	& > div:not(.outerContent) {
		flex-grow: 1; min-width: 16px; min-height: 16px;
	}

	& > .outerContent {
		display: flex; justify-content: center; align-items: center;

		& > div:not(.innerContent) {
			flex-grow: 1; min-width: 16px; min-height: 16px;
		}

		& > .innerContent {
			flex-grow: 1; overflow-y: auto; margin: 16px 0;
			max-height: calc(100vh - 32px);

			scrollbar-width: thin;

			&::-webkit-scrollbar { width: 8px; }
			&::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.1); }
			&::-webkit-scrollbar-thumb {
				background: rgba(0, 0, 0, 0.1);
				border-radius: 8px;
			}
		}
	}

	&.displayed {
		display: flex;
	}

	&.visible {
		opacity: 1; pointer-events: auto;
	}
}
