.titleBar {
	display: flex; align-items: center; justify-content: space-between;
	height: 28px; gap: 8px;

	& > .branding {
		flex-grow: 1; background-repeat: no-repeat; background-size: contain;
		height: 100%; background-position: left center; display: block;
	}

	& > .close {
		background-color: rgba(0, 0, 0, 0.05); border-radius: 4px;
		padding: 8px; cursor: pointer;

		& > img {
			display: block; height: 12px;
		}
	}
}
