.slowLoader {
	width: 24px; height: 24px;

	animation-name: slowLoaderRotate; animation-duration: 4s;
	animation-iteration-count: infinite; animation-timing-function: linear;
}

@keyframes slowLoaderRotate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
