.statsBar {
	height: 32px; width: 100%; display: flex; overflow: hidden;
	border-radius: 6px;

	& > div { transition: width 1s; }

	& > .created { background-color: rgba(255, 255, 255, 0.2); }
	& > .querying { background-color: #e2ca99; }
	& > .error { background-color: #CC4433; }
	& > .matched { background-color: #4f8f47; }
	& > .unmatched { background-color: #253a22; }
}
