.icon {
	background-color: rgba(8, 211, 89, 0.08); color: #08B54D; border-radius: 30px;
	flex-basis: auto;  text-align: center; padding: 4px 8px; font-weight: 700; font-feature-settings: 'ss02' on; font-family: DM Sans;
	font-style: normal; font-size: 12px; letter-spacing: -0.6px; line-height: 16px;
}

.left {
	display: flex; justify-content: left; align-items: left; gap: 10px;
}

.center {
	display: flex; justify-content: center; align-items: center; gap: 10px;
}