:root {
	--color-background-ambient: #161b22;
	--color-background-container: #21262d;
	--color-background-hover: hsl(215, 15%, 18%);
	--color-border: #444;

	--color-theme: #b498fc;
	--color-text-default: rgba(255, 255, 255, 0.9);
	--color-text-title: rgba(255, 255, 255, 1);

	--font-default: 'QuickSand', sans-serif;
	--font-size-default: 13.3333px;

	--border-radius: 4px;
}
