.toggleSwitch {
	border-radius: 12px; cursor: pointer; transition: background-color 0.4s;
	background-color: rgba(0, 0, 0, 0.1); position: relative;
	box-shadow: inset 1px 1px 5px 3px rgba(0, 0, 0, 0.1);

	min-width: 40px; max-width: 40px; min-height: 20px; max-height: 20px;

	& > div {
		position: absolute; background-color: #FFF; width: 20px;
		height: 20px; box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
		border-radius: 10px; transition: transform 0.4s;

		& > div {
			width: 10px; height: 10px; border: 2px solid rgba(0, 0, 0, 0.1);
			border-radius: 7px; position: absolute; top: 3px; left: 3px;
			border-top-color: #b498fc; opacity: 0;
			transition: opacity 0.4s;

			animation-name: loading; animation-timing-function: linear;
			animation-duration: 1500ms;
			animation-iteration-count: infinite;

			@keyframes loading {
				0% { transform: rotate(0deg); }
				100% { transform: rotate(720deg); }
			}
		}
	}

	&.enabled {
		background-color: #b498fc;

		& > div { transform: translateX(20px); }
	}

	&.loading {
		cursor: default;

		& > div {
			transform: translateX(10px);

			& > div { opacity: 1; }
		}
	}
}
