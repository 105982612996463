.textEntry {
	border: 1px solid rgba(166, 166, 166, 0.24); display: flex; overflow: hidden;
	align-items: center; border-radius: 6px; background-color: #FFF;
	transition: background-color 0.4s;

	&.disabled { background-color: #DADADA; }

	& > input {
		font-family: var(--rave-font-default); color: var(--rave-color-text-default);
		font-size: 16px; line-height: 22px; outline: 0; border: 0; flex-grow: 1;
		padding: 12px; background-color: rgba(0, 0, 0, 0);
	}

	& > img {
		margin: 12px;

		&.clickable { cursor: pointer; }
	}
}
