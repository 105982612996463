.toast {
	position: relative; width: 100%;

	& > div {
		padding: 6px; background-color: rgba(0, 0, 0, 0.9); color: #FFF;
		font-weight: 500; border-radius: 6px; font-size: x-small;
		position: absolute; left: 50%; top: 0; opacity: 0;
		transform: translate(-50%, -20%); pointer-events: none;
		transition: transform 0.4s, opacity 0.4s;
	}

	&.visible > div {
		transform: translate(-50%, -40%); opacity: 1;
	}
}
