@import '../styles/media.scss';

.ugcTierList {
	display: flex; flex-direction: column; gap: 16px; margin: 24px 0;

	& > .separator {
		display: flex; align-items: center; color: #AAA; gap: 12px;
		font-size: 0.8rem;

		& > div:first-child, & > div:last-child {
			min-height: 1px; flex-grow: 1; background-color: #CCC;
			max-height: 1px; display: block;
		}
	}

	& > .reward {
		display: flex; align-items: top; gap: 12px;

		& > .financialIcon {
			display: flex; align-items: center; position: relative;
			justify-content: center; border-radius: 4px;
			border: 0.5px solid rgba(0, 0, 0, 0.04);

			@media #{$desktop-and-tablet} {
				min-width: 40px; max-width: 40px; min-height: 40px;
				max-height: 40px; font-size: 1.2rem;
			}

			@media #{$mobile} {
				min-width: 32px; max-width: 32px; min-height: 32px;
				max-height: 32px; font-size: 1rem;
			}

			& > img {
				position: absolute; top: -4px; right: -4px;
			}
		}

		& > .skuIcon {
			background-size: cover; background-position: center;
			border-radius: 4px; display: block;

			@media #{$desktop-and-tablet} {
				min-width: 40px; max-width: 40px; min-height: 40px;
				max-height: 40px; font-size: 1.2rem;
			}

			@media #{$mobile} {
				min-width: 32px; max-width: 32px; min-height: 32px;
				max-height: 32px; font-size: 1rem;
			}
		}

		& > .text {
			flex-grow: 1; word-break: break-word;

			& > div:first-child {
				font-size: 16px; line-height: 20px; font-weight: bold;
				color: #1E1E1E;
			}
		}
	}
}
