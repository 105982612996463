.sideBarHeader {
	display: flex; align-items: center;

	& > img { height: 48px; margin-right: 8px; }
	& > div {
		overflow: hidden;

		& > div:first-of-type {
			font-weight: bold; color: var(--color-text-title);
		}
	}
}

.sideBarUser {
	display: flex; align-items: center;

	& > div:first-of-type {
		flex-grow: 1; overflow: hidden; margin-right: 8px;

		& > div:first-of-type {
			font-weight: bold; color: var(--color-text-title);
		}
	}
}
