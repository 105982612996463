.liveIndicator {
	background-color: rgba(0, 0, 0, 0.3); padding: 6px 8px; border-radius: 6px;
	display: inline-block;

	& > div {
		display: flex; align-items: center; gap: 6px;

		& > div:first-of-type {
			background-color: #A00; width: 7px; height: 7px;
			border-radius: 50%; animation-name: liveBlink;
			animation-duration: 3s; animation-iteration-count: infinite;
			animation-timing-function: ease-in-out;
			box-shadow: 0 0 10px 3px #A00;

			@keyframes liveBlink {
				0% { background-color: #A00; box-shadow: 0 0 10px 3px #A00; }
				50% { background-color: #F00; box-shadow: 0 0 10px 3px #F00; }
				100% { background-color: #A00; box-shadow: 0 0 10px 3px #A00; }
			}
		}
	
		& > div:last-of-type {
			font-weight: bold; font-size: 0.7rem;
		}
	}
}
