@import '../../styles/media.scss';

.frameUser {
	display: flex; width: 100%;

	@media #{$mobile} {
		flex-direction: column;
	}

	& > div:first-of-type {
		@media #{$desktop-and-tablet} {
			max-width: 288px; min-width: 288px; margin-right: 32px;
		}

		@media #{$mobile} { margin-bottom: 32px; }
	}

	& > div:last-of-type {
		min-width: 0;
		@media #{$desktop-and-tablet} {
			flex-grow: 1;
		}
	}
}
