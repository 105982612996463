.educationTile {
	display: flex; align-items: flex-start; margin-bottom: 4px;
	padding: 4px;

	& > .icon {
		border-width: 1px; border-style: solid; padding: 8px;
		margin-right: 12px; border-radius: 6px; max-width: 40px;
		max-height: 40px; min-width: 40px; min-height: 40px;
		position: relative; box-sizing: border-box;

		& > img {
			position: absolute; top: 50%; left: 50%;
			transform: translate(-50%, -50%); max-width: 26px; max-height: 21px;
		}
	}

	& > .character {
		border-width: 1px; border-style: solid; padding: 10px;
		margin-right: 12px; border-radius: 6px; max-width: 40px;
		max-height: 40px; min-width: 40px; min-height: 40px;
		position: relative; box-sizing: border-box; text-align: center;
		font-size: 20px; font-weight: bold;
		background-color: rgba(99, 82, 237, 0.06);
	}

	& > .text {
		& > div:first-of-type {
			font-weight: bold; color: var(--rave-color-text-title);
			margin-bottom: 4px;
		}

		& > div:last-of-type {
			font-size: small; color: var(--rave-color-text-default);
		}
	}


	&.standard {
		& > .icon {
			border-color: var(--rave-color-theme);
			filter: drop-shadow(0px 0px 3px rgba(99, 82, 237, 0.75));

			& > img {
				filter: var(--rave-filter-theme);
			}
		}
	}

	&.alternate {
		& > .icon {
			border-color: var(--rave-color-theme-alternate);

			& > img {
				filter: var(--rave-filter-theme-alternate);
			}
		}
	}

	&.filled {
		& > .icon {
			border: 0; filter: brightness(1) saturate(100%);
			background: linear-gradient(to bottom, var(--rave-color-theme), #ab77de);
		}
	}

}
